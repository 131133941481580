import {DIRECTUS_SERVER_URL} from '@/config';

export const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));


// Data Fetching Configuration
const fetchData = async (url) => {
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    });
    return await response.json();
};

const createData = async (url, data = null, removeApplicationType = null) => {

    let config = {
        method: 'POST',
        headers: {
            'Content-Type': !removeApplicationType ? 'application/json' : '',
        }
    };

    if (data) {
        config.body = JSON.stringify(data);
    }

    if (removeApplicationType) {
        delete config.headers['Content-Type'];
        config.body = data;
    }

    return await fetch(url, config);
};

const patchData = async (url, data) => {

    return await fetch(url, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
//            'Authorization': `Bearer ${access_token}`
        },
        body: JSON.stringify(data)
    });
};

const deleteData = async (url, data) => {

    let config = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        }
    };

    if (data) {
        config.body = JSON.stringify(data);
    }

    return await fetch(url, config);
};




export const getUser = async (userId) => {
    const url = `${DIRECTUS_SERVER_URL}/users/${userId}`;
    return await fetchData(url);
};

export const getUserData = async (userId) => {
    const url = `${DIRECTUS_SERVER_URL}/users/${userId}/bookmarks-publications`;
    return await fetchData(url);
};

export const updateUserData = async (userId, userData) => {
    const url = `${DIRECTUS_SERVER_URL}/users/${userId}`;
    return await patchData(url, userData);
};


// Adverts
export const getAds = async (date) => {
    const url = `${DIRECTUS_SERVER_URL}/ads/${date}`;
    return await fetchData(url);
};


//Weeks
export const getSelectedWeekPrintouts1 = async (weekId, all = false, page = 1, pageSize = 12, search = '') => {
    let url = `${DIRECTUS_SERVER_URL}/printouts/selected/week`;

    if (weekId) {
        url += `/${weekId}`;
    }

    if (all) {
        url += `?all=true`;
    } else {
        url += `?page=${page}&pageSize=${pageSize}`;
        if (search) {
            url += `&search=${encodeURIComponent(search)}`;
        }
    }

    return await fetchData(url);
};

export const getSelectedWeekPrintouts = async (date) => {

    const selectedDate = date ? date : '';

    let url = `${DIRECTUS_SERVER_URL}/printouts/selected-date/week/${selectedDate}`;

    return await fetchData(url);
};

export const getAdjacentWeeks = async (weekId = '') => {
    const url = `${DIRECTUS_SERVER_URL}/weeks/adjacent-weeks/${weekId}`;
    return await fetchData(url);
};

export const getWeeksAroundDate = async (selectedDate = '') => {
    const url = `${DIRECTUS_SERVER_URL}/weeks/weeks-around-date/${selectedDate}`;
    return await fetchData(url);
};

export const getCurrentWeek = async () => {

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(currentDate.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;

    const url = `${DIRECTUS_SERVER_URL}/weeks/current-week?date=${formattedDate}`;
    return await fetchData(url);
}


//Languages
export const getLanguagesData = async () => {
    const url = `${DIRECTUS_SERVER_URL}/languages`;
    return await fetchData(url);
};


// Publications
export const getPublicationData = async (all = false, page = 1, pageSize = 12, search = '') => {
    let url = `${DIRECTUS_SERVER_URL}/publications`;

    if (all) {
        url += `?all=true`;
    } else {
        url += `?page=${page}&pageSize=${pageSize}`;
        if (search) {
            url += `&search=${encodeURIComponent(search)}`;
        }
    }

    return await fetchData(url);
};

export const createPublication = async (data) => {
    const url = `${DIRECTUS_SERVER_URL}/publications`;
    return await createData(url, data, true);
}

export const getPublicationPrintouts = async (slug) => {
    const url = `${DIRECTUS_SERVER_URL}/publications/${slug}/printouts`;
    return await fetchData(url);
};


// Followings
export async function getMyPublications(userId) {
    const url = `${DIRECTUS_SERVER_URL}/followings/user/${userId}`;
    return await fetchData(url);
}

export async function getMyFollowedPublicationIds(userId) {
    const url = `${DIRECTUS_SERVER_URL}/followings/user/${userId}/followed-publication-ids`;
    return await fetchData(url);
}

export const toggleFollowPublication = async (data, shouldFollow) => {
    const url = `${DIRECTUS_SERVER_URL}/followings`;
    return shouldFollow ? await createData(url, data) : await deleteData(url, data);
}

export async function updateMyEmailSettings(userId, data) {
    const url = `${DIRECTUS_SERVER_URL}/update-email-settings/${userId}`;
    return await patchData(url, data);
}

export async function updateWeeklyEmailForPublication(userId, data) {
    const url = `${DIRECTUS_SERVER_URL}/followings/${userId}/weekly-email`;
    return await patchData(url, data);
}

export async function getCurrentWeekFollowingPrintouts(userId) {
    const url = `${DIRECTUS_SERVER_URL}/followings/user/${userId}/current-week-printouts`;
    return await fetchData(url);
}

// Printouts
export async function getPrintoutById(printoutId) {
    const url = `${DIRECTUS_SERVER_URL}/printouts/${printoutId}`;
    return await fetchData(url);
}

export async function addNewPrintout(data) {
    const url = `${DIRECTUS_SERVER_URL}/printouts`;
    return await createData(url, data);
}


// Bookmarks
export async function getMyBookmarks(userId) {
    const url = `${DIRECTUS_SERVER_URL}/bookmarks/user/${userId}`;
    return await fetchData(url);
}

export async function getMyBookmarkedPrintoutIds(userId) {
    const url = `${DIRECTUS_SERVER_URL}/bookmarks/user/${userId}/printouts`;
    return await fetchData(url);
}

export const toggleBookmarkPrintout = async (data, bookmark) => {
    const url = `${DIRECTUS_SERVER_URL}/bookmarks`;
    return bookmark ? await createData(url, data) : await deleteData(url, data);
}


// Periods
export const getPeriods = async () => {
    const url = `${DIRECTUS_SERVER_URL}/periods`;
    return await fetchData(url);
}

export async function getPeriodById(periodId) {
    const url = `${DIRECTUS_SERVER_URL}/periods/${periodId}`;
    return await fetchData(url);
}

// Files & Folders
export async function getFolderByName(folderName) {
    const url = `${DIRECTUS_SERVER_URL}/folders/${folderName}`;
    return await fetchData(url);
}

export async function uploadPrintout(data) {
    const url = `${DIRECTUS_SERVER_URL}/printouts`;

    return await createData(url, data, true);
}


// Publishers Publications
export async function getPublisherPublications(publisherId) {
    const url = `${DIRECTUS_SERVER_URL}/publications/publisher/${publisherId}/publications`;
    return await fetchData(url);
}

export async function getPublisherPrintouts(publisherId) {
    const url = `${DIRECTUS_SERVER_URL}/printouts/publisher/${publisherId}/printouts`;
    return await fetchData(url);
}
