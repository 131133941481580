import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();
const { NEXT_PUBLIC_DIRECTUS_SERVER_URL, NEXT_PUBLIC_PDFJS_LICENCE_KEY, NEXT_PUBLIC_API_URL } = publicRuntimeConfig;

export const API_URL = NEXT_PUBLIC_API_URL || 'https://localhost:3000';

export const PDFJS_LICENCE_KEY = NEXT_PUBLIC_PDFJS_LICENCE_KEY || 'CfShQ4GaHdZFhYKFuvk6';

export const DIRECTUS_SERVER_URL = NEXT_PUBLIC_DIRECTUS_SERVER_URL || 'http://directus.local:8055/api/v1';

