import React, {createContext, useContext, useEffect, useState} from 'react';
import {getSelectedWeekPrintouts} from '@/lib/api';

export const PrintoutContext = createContext({});

export const PrintoutProvider = ({ children }) => {

    const [printouts, setPrintouts] = useState([]);
    const [initialNumberOfPrintouts, setInitialNumberOfPrintouts] = useState(0);

    const [ printoutsLoading, setPrintoutsLoading ] = useState(true);
    const [followingPrintouts, setFollowingPrintouts] = useState([]);

    const [algoliaPrintouts, setAlgoliaPrintouts] = useState([]);

    const fetchInitialWeeklyPrintouts = async (loadMore = false) => {
        try {

            let today = new Date();
            today = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;

            const response = await getSelectedWeekPrintouts(today);

            setPrintouts(response?.printouts || []);
            setInitialNumberOfPrintouts(response.pagination?.totalPrintouts || 0);
        } catch (error) {
            console.error('Failed to fetch printouts:', error);
        }
    };

    useEffect(() => {
        fetchInitialWeeklyPrintouts().then(() => {
            setPrintoutsLoading(false);
        });
    }, []);

    const contextValues = {
        printouts,
        setPrintouts,
        initialNumberOfPrintouts,
        setInitialNumberOfPrintouts,
        printoutsLoading,
        setPrintoutsLoading,
        followingPrintouts,
        setFollowingPrintouts,

        algoliaPrintouts,
        setAlgoliaPrintouts
    };

    return (
        <PrintoutContext.Provider value={contextValues}>
            {children}
        </PrintoutContext.Provider>
    );
};

export const usePrintout = () => useContext(PrintoutContext);
