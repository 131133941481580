import React, {createContext, useContext, useState} from 'react';

export const LoginActionContext = createContext();

export const LoginActionProvider = ({ children }) => {

    const [actionType, setActionType] = useState('Hello');

    const contextValues = {
        actionType,
        setActionType
    };

    return (
        <LoginActionContext.Provider value={contextValues}>
            {children}
        </LoginActionContext.Provider>
    );
};

export const useLoginAction = () => useContext(LoginActionContext);
